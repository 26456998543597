import axios from "axios";
import gsap from "gsap";
import ButtonBracket from "./ButtonBracket";

export default class PG_Theme {
    constructor() {
        this.loadThemeList();
        // this.loadThemeListList()
    }
    async loadThemeList() {
        try {
            const response = await axios.get(`https://stage-api.blurblur.ai/api/themes/`);
            response.data.results.map((r) => this.createContent(r));
        } catch (error) {
        } finally {
        }
    }

    async loadThemeListList() {
        let _count = 0;

        const buttonContainer = document.querySelector(".list-button-array");
        const listContainer = document.querySelector(".list-col-array");

        try {
            const response = await axios.get(`https://stage-api.blurblur.ai/api/theme/list`);

            for (const [key, value, index] of Object.entries(response.data)) {
                const button = document.createElement("button");
                button.innerHTML = key;
                buttonContainer.appendChild(button);
                button.setAttribute("data-char", key);

                if (value.length > 0) {
                    const container = document.createElement("div");
                    container.classList.add("col");
                    container.setAttribute("data-char", key);
                    container.innerHTML = `
                        <p class="char">${key}</p>
                        <div class="row-container"></div>
                    `;

                    value.map((v) => {
                        const _link = document.createElement("a");
                        _link.href = v.get_absolute_url;
                        _link.innerHTML = v.name;

                        container.querySelector(".row-container").appendChild(_link);
                        this.loadThemeRead(v.get_absolute_url);
                    });

                    listContainer.appendChild(container);
                }
            }
        } catch (error) {
        } finally {
        }
    }

    async loadThemeRead(name) {
        try {
            const response = await axios.get(`https://stage-api.blurblur.ai${name}`);
            this.createContent(response.data);
        } catch (error) {

        } finally {
        }
    }
    createViewer(props) {
        const button = document.createElement("div");
        button.classList.add("model-thumb");
        button.innerHTML = `<img data-src=${props.resize_image} />`;
        const image = button.querySelector("img");
        image.src = image.getAttribute("data-src");
        image.onload = () => {
            if (!image.hasAttribute("data-load")) {
                image.setAttribute("data-load", true);
                gsap.to(button, { opacity: 1, duration: 0.4, ease: "power1.inOut" });
            }
        };
        return button;
    }

    createContent(props) {
        const { name, description, images, theme_detail_url } = props;

        const container = document.querySelector(".theme-container");
        const content = document.createElement("a");
        const _link = `/theme/detail?name=${name.toLowerCase().split(" ").join("-")}`;
        content.href = _link;
        content.classList.add("theme-content");

        content.innerHTML = `
            <div class="theme-info">
                <div class="txt-info">
                    <div class="title">${name}</div>
                    <div class="sub">${description}</div>
                </div>
                <a class="button-bracket" href="${_link}">
                    <span class="icon-bracket"></span><span class="rect"></span>
                    <span class="row"><span class="text">VIEW MORE</span><span class="rect"></span><span class="icon-arrow"></span></span>
                    <span class="rect"></span><span class="icon-bracket reverse"></span>
                </a>
            </div>
            <div class="theme-thumbnail">
       
            </div>
        `;
        images.map((r) => {
            if (r.resize_image) {
                content.querySelector(".theme-thumbnail").appendChild(this.createViewer(r));
            }
        });

        container.appendChild(content);

        new ButtonBracket({ button: content.querySelector(".button-bracket") });
    }
}
