import gsap from "gsap";
import axios from "axios";
import ButtonBracket from "./ButtonBracket";

import { toast, copyClipboard } from "./utis";

export default class Viewer {
    constructor() {
        // this.layer = document.querySelector(".layer");
        // this.content = this.layer.querySelector(".page-share");
        this.container = document.querySelector(".page-share");
        this.content = null;
        this.isMobile = document.body.classList.contains("view-mobile");
    }

    disappearLayer() {
        const that = this;
        if (this.layer) {
            gsap.to(this.layer, {
                opacity: 0,
                display: "none",
                duration: 0.3,
                ease: "power1.inOut",
                onComplete: () => {
                    if (this.layer) {
                        gsap.set(that.layer, { display: "none", pointerEvents: "none" });
                        that.content.remove();
                    }
                    document.body.classList.remove("is-lock");
                    gsap.set(this.container, { opacity: 0 });
                },
            });
        } else {
            gsap.to(that.content, {
                opacity: 0,
                display: "none",
                duration: 0.3,
                ease: "power1.inOut",
                onComplete: () => {
                    that.content.remove();
                    that.content = null;
                },
            });
        }
    }

    appearLayer() {
        if (document.querySelector(".layer")) {
            this.layer = document.querySelector(".layer");
            document.body.classList.add("is-lock");
            gsap.set(this.layer, { display: "flex", opacity: 1 });

            if (!this.layer.querySelector(".button-close").hasAttribute("data-load")) {
                this.layer.querySelector(".button-close").addEventListener("click", () => {
                    this.disappearLayer();
                });
            }
        }
        if (this.content) {
            [...this.container.querySelectorAll(".button-icon")].forEach((button, i) => {
                if (!button.hasAttribute("data-load")) {
                    button.setAttribute("data-load", true);
                    // new ButtonBracket({ button: button });

                    if (i == 1) {
                        button.addEventListener("click", () => {
                            copyClipboard(button);
                        });
                    }

                    if (this.layer) {
                        if (i == 0) {
                            if (document.querySelector(".page-detail")) {
                                button.removeAttribute("href");
                                button.addEventListener("click", (e) => {
                                    e.preventDefault();
                                    this.disappearLayer();
                                });
                            }
                        }
                    }
                }
            });
            if (this.container.querySelector(".link-tag")) {
                if (document.querySelector(".page-theme-detail")) {
                    if (!this.isMobile) {
                        const links = gsap.utils.toArray(".link-tag", this.container);
                        links.forEach((link) => {
                            link.removeAttribute("href");
                            link.addEventListener("click", (e) => {
                                e.preventDefault();
                                this.disappearLayer();
                            });
                        });
                    }
                }
            }
            gsap.to(this.container, {
                opacity: 1,
                duration: 0.25,
                ease: "power1.inOut",
                onComplete: () => {
                    if (this.layer) {
                        gsap.set(this.layer, { pointerEvents: "auto" });
                    }
                },
            });
        }
    }

    init() {

    }

    async loadDATA(type, id) {
        try {
            const response = await axios.get(`https://stage-api.blurblur.ai/api/${type}/images/${id}`);

            if (response.data) {
                const { image, model_en_slug, name_en, name_ko, theme_slug } = response.data;
                this.createLayer({
                    index: id,
                    name_en: name_en,
                    slug: model_en_slug,
                    name: `${name_ko}`,
                    image: image,
                    type: type,
                    theme_slug: theme_slug ? theme_slug : "",
                });
            }
        } catch (error) {
            // window.location.href = "https://blurblur.ai/404/404.html";

        } finally {
        }
    }

    createLayer(props) {

        if (this.content) {
            this.content.remove();
            this.content = null;
        }
        const { index, name, name_en, image, type } = props;

        const slug = name_en.toLowerCase().split(" ").join("-");

        const layer = document.createElement("div");
        layer.classList.add("share-container");


        const theme_name = type == "theme" ? props.theme_slug.split("-").join(" ") : "";
        const theme_url = type == "theme" ? props.theme_slug : "";

        layer.innerHTML = `
        <div class="share-image">
            ${document.querySelector(".layer") && !this.isMobile ? `<button class="button-close bg"></button>` : ""}
            <div class="model-thumb">
                <div class="inner">
                    <img alt="${slug}" class="main-thumb" data-src="${image}" />
                    <div class="texts">
                        <div class="model-name">${name} / ${name_en}</div>
                        ${theme_name !== "" ? `<a class="link-tag" href="/theme/detail?name=${theme_url}">${theme_name}</a>` : ""}
                    </div>
                </div>
            </div>
        </div>

    
        <div class="contact-sticky">
            <div class="sticky-container">
                <a class="button-icon" href="https://blurblur.ai/model/${slug}">
                    <span class="row"><span class="icon-arrow"></span><span class="text ko">모델 보기</span></span>
                </a>
                <div class="row-container name-container">
                    <div class="model-name">${name} / ${name_en}</div>
                    ${theme_name !== "" ? `<a class="link-tag" href="/theme/detail?name=${theme_url}">${theme_name}</a>` : ""}
                </div>
                ${
                    type == "theme"
                        ? `<button class="button-icon button-share" data-value="https://blurblur.ai/view?type=theme&index=${index}">`
                        : `<button class="button-icon button-share" data-value="https://blurblur.ai/view?type=port&index=${index}">`
                }
                    <span class="row"><span class="text ko">공유하기</span><span class="icon-share"></span></span>
                </button>
            </div>
        </div>`;

        const _image = layer.querySelector(".model-thumb img");
        _image.src = _image.getAttribute("data-src");

        _image.onload = () => {
            if (!_image.hasAttribute("data-load")) {
                _image.setAttribute("data-load", true);
                gsap.to(layer.querySelector(".share-image"), { opacity: 1, duration: 0.4, ease: "power1.inOut" });
            }
        };

        if (layer.querySelector(".button-close.bg")) {
            layer.querySelector(".button-close.bg").addEventListener("click", () => {
                this.disappearLayer();
            });
        }

        this.container.prepend(layer);
        this.content = layer;
        this.appearLayer();
    }
}
