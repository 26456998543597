import gsap from 'gsap';
import axios from 'axios';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

import ButtonBracket from './ButtonBracket';

import TextAnim from './TextAnim';
export default class PG_Index {
    async loadDATA(url) {
        try {
            this.dt = 0;
            document.querySelector('.model-container').innerHTML = '';
            const response = await axios.get(`https://stage-api.blurblur.ai${url ?? '/api/ai-model'}`);

            const model = response.data;

            const emptySpaceText = [
                {
                    empty: true,
                    text: 'Fit, Trendy, Beautiful, Colorful<br />Recommended for Fashion',
                    bottom: true,
                },
                {
                    empty: true,
                    text: 'Beautiful, Radiant, Colorful, Healthy<br />Recommended for Cosmetics',
                    link: '/contact',
                },
                {
                    empty: true,
                    text: 'Healthy, Energetic, Muscular, Passionate<br />Recommended for Leisure/Sports',
                    bottom: true,
                },
                {
                    empty: true,
                    text: 'Lifestyle, Technological, Innovative, Comfortable<br />Recommended for Digital/Consumer Electronics',
                },
                {
                    empty: true,
                    text: 'Warm, Stable, Comfortable, Lifestyle<br />Recommended for Furniture/Interior',
                    bottom: true,
                },
                {
                    empty: true,
                    text: 'Healthy, Glow, Fresh, WellnessRecommended for Food',
                },
            ];

            const _models = [];
            let _index = 0;
            model.forEach((model, i) => {
                if (i >= 2) {
                    if ((i - 2) % 5 == 0) {
                        _models.push(emptySpaceText[_index > emptySpaceText.length ? _index % emptySpaceText.length : _index]);
                        _index++;
                    }
                } else {
                }
                _models.push(model);
            });
            _models.map((_d, index) => {
                if (_d.empty) {
                    new EmptyGrid(_d);
                } else {
                    new Model(_d);
                }
            });
        } catch (error) {
        } finally {
            gsap.utils.toArray('.model img').forEach((img) => {
                img.src = img.src;
                img.onload = () => {
                    if (!img.hasAttribute('data-load')) {
                        img.setAttribute('data-load', true);

                        gsap.delayedCall(this.dt, () => {
                            gsap.to(img, { opacity: 1, duration: 0.4, ease: 'power1.inOut' });
                        });
                        this.dt += 0.08;
                    }
                };
            });

            this.intro();
        }
    }
    async loadDATA_Race() {
        try {
            const response = await axios.get('https://stage-api.blurblur.ai/api/groups');

            if (response) {
                // console.log(response.data);

                this.createRace({
                    name_en: 'All',
                    url: '/api/ai-model',
                    init: true,
                });

                response.data.map((d) => {
                    this.createRace(d);
                });
            }
        } catch (error) {
            // console.log(error);
        } finally {
            let mm = gsap.matchMedia();

            // mm.add('(max-width: 768px)', () => {
            //     ScrollTrigger.create({
            //         trigger: '.category-race',
            //         start: () => 'top top+=64px',
            //         endTrigger: '#view',
            //         end: () => `bottom+=400% bottom`,
            //         pin: '.race-sticky',
            //         pinSpacing: false,
            //         pinType: 'fixed',
            //         // markers: true,
            //         id: 'sticky-race',
            //         // refreshPriority: 0,
            //         anticipatePin: 1,
            //     });
            // });

            // mm.add('(min-width: 769px)', () => {
            //     const main = document.querySelector('.page-index');

            // });
            ScrollTrigger.create({
                trigger: '.model-container',
                start: () => 'top-=58px top+=66px',
                end: () => `bottom+=40000px bottom`,
                pin: '.race-sticky',
                pinSpacing: false,
                pinType: document.querySelector('.ScrollSmoother-wrapper') ? 'transform' : 'fixed',
                // markers: true,
                anticipatePin: 1,
                markers: false,
                refreshPriority: 0,
                id: 'sticky-race',
                // markers: true,
            });
        }

        ScrollTrigger.refresh();
    }
    // selectChip(button) {
    //     gsap.killTweensOf(button, 'all');
    //     gsap.to(button, { backgroundColor: '#000', duration: 0.4, ease: 'power1.inOut' });
    //     gsap.to(button, { color: '#fff', duration: 0.4, ease: 'power1.inOut' });
    //     gsap.to(button, { borderColor: '#000', duration: 0.4, ease: 'power1.inOut' });
    // }
    // hoverChip(button) {
    //     gsap.killTweensOf(button, 'all');
    //     gsap.to(button, { backgroundColor: '#fff', duration: 0.4, ease: 'power1.inOut' });
    //     gsap.to(button, { color: '#000', duration: 0.4, ease: 'power1.inOut' });
    //     gsap.to(button, { borderColor: '#dfdfdf', duration: 0.4, ease: 'power1.inOut' });
    // }
    // leaveChip(button) {
    //     gsap.killTweensOf(button, 'all');
    //     gsap.to(button, { backgroundColor: 'transparent', duration: 0.4, ease: 'power1.inOut' });
    //     gsap.to(button, { color: '#000', duration: 0.4, ease: 'power1.inOut' });
    //     gsap.to(button, { borderColor: '#dfdfdf', duration: 0.4, ease: 'power1.inOut' });
    // }
    createRace(props) {
        const { name_en, url, init } = props;
        const button = document.createElement('button');
        button.classList.add('button-chip');
        button.innerHTML = `<span>${name_en}</span>`;
        this.raceContainer.appendChild(button);

        if (init) {
            button.classList.add('is-selected');
        }
        const container = document.querySelector('.model-container');

        button.addEventListener('click', () => {
            if (button.classList.contains('is-selected')) return;
            // console.log(1, document.querySelector('.model-container').getBoundingClientRect());
            gsap.utils.toArray('.button-chip', this.raceContainer).forEach((r, i) => {
                if (r == button) {
                    r.classList.add('is-selected');
                } else {
                    r.classList.remove('is-selected');
                }
            });
            gsap.set(this.raceContainer, { scrollTo: { x: button, offsetX: 24 } });

            if (container.querySelector('.model')) {
                gsap.to(container, {
                    opacity: 0,
                    duration: 0.6,
                    ease: 'power1.inOut',
                    onStart: () => {
                        if (ScrollTrigger.getById('sticky-race')) {
                            ScrollTrigger.getById('sticky-race').refresh();
                            ScrollTrigger.getById('sticky-race').update();
                            if (ScrollTrigger.getById('sticky-race').progress > 0) {
                                // console.log('시작점 : ', ScrollTrigger.getById('sticky-race').start);

                                const getYv = () => {
                                    if (window.innerWidth > 768) {
                                        return ScrollTrigger.getById('sticky-race').start - document.querySelector('.category-race').clientHeight - document.querySelector('.header').clientHeight;
                                    } else {
                                        return ScrollTrigger.getById('sticky-race').start 
                                    }
                                }
                                gsap.to(window, {
                                    scrollTo: {
                                        y: () => getYv(),
                                        // offsetY: () => yv(),
                                        duration: 0.5,
                                        // offsetY: 20,
                                        onComplete: () => {

                                            
                                        },
                                    },
                                });
                            } else {
                                // gsap.set(window, { scrollTo: { y: 0 } });
                            }
                        }
                    },
                });

                let arr = gsap.utils.toArray('.model', container);
                arr.push(gsap.utils.toArray('.grid-container', container));

                gsap.to(arr, {
                    stagger: {
                        amount: 0.1,
                        from: 'random',
                    },
                    opacity: 0,
                    duration: 0.4,
                    ease: 'power1.inOut',
                });
            }
            this.loadDATA(url);

            if (ScrollTrigger.getById('sticky-race')) {
                ScrollTrigger.getById('sticky-race').refresh();
            }
        });
    }
    constructor() {
        this.dt = 0;
        this.bv = {
            blur: 0,
        };
        this.self = document.querySelector('.page-index');
        this.raceContainer = document.querySelector('.category-race');
        this.init();

        this.resetY = 0;

        gsap.registerPlugin(ScrollToPlugin);
    }

    init() {
        this.loadDATA();
        this.loadDATA_Race();
    }
    intro() {
        const self = this.self;

        // gsap.set("#header ", { y: "-100%" });
        // gsap.to("#header ", { y: 0, duration: 0.8, ease: "power2.out" });
        // gsap.to("#header ", {
        //     autoAlpha: 1,
        //     duration: 0.7,
        //     ease: "power1.inOut",
        //     delay: 1.4,
        //     onComplete: () => {},
        // });
        gsap.to(self, { opacity: 1, duration: 0.7, ease: 'power1.inOut' });

        // const bv = {};
        // bv.blur = 0;
        // // ----- logo
        // gsap.set(self.querySelector(".logo-large"), { "--blur": `${bv.blur}px` });

        // gsap.to(self.querySelector(".logo-large"), {
        //     opacity: 1,
        //     duration: 1.0,
        //     ease: "power1.inOut",
        // });

        // gsap.to(bv, {
        //     blur: 100,
        //     duration: 1.4,
        //     delay: 0.6,
        //     ease: "power1.inOut",
        //     onUpdate: () => {
        //         gsap.set(self.querySelector(".logo-large"), { "--blur": `${bv.blur}px` });
        //     },
        // });

        // gsap.to(self.querySelector(".logo-large"), {
        //     autoAlpha: 0,
        //     duration: 1.4,
        //     delay: 0.2,
        //     ease: "power1.inOut",
        //     onComplete: () => {
        //         gsap.set(self.querySelector(".logo-large"), { display: "none" });
        //         gsap.to("#header .logo", { autoAlpha: 1, duration: 0.4, ease: "power1.inOut" });
        //     },
        // });
        // ----- logo

        gsap.killTweensOf(self.querySelector('.model-container'), 'all');
        gsap.to(self.querySelector('.model-container'), {
            autoAlpha: 1,
            duration: 1.0,
            ease: 'power1.inOut',
            delay: 0,
            onComplete: () => {
                gsap.delayedCall(0.2, () => {
                    document.body.classList.add('load');
                });
            },
        });
    }
}

class Model {
    constructor(props) {
        const { detail_url, individuality_keyword_en, name_en, name_en_slug, name_ko, thumbnail } = props;

        this.name_en = name_en;
        this.thumbnail = thumbnail;
        this.name_en_slug = name_en_slug;
        this.individuality_keyword_en = individuality_keyword_en;
        this.container = document.querySelector('.model-container');

        this.isMobile = document.body.classList.contains('view-mobile');
        this.content = null;
        this.nameCardRef = null;

        // if (gridText) {
        //     if (link) {
        //         this.link = link
        //     }
        //     this.text = gridText
        //     this.createEmptyGrid()
        // } else {

        // }

        this.createLink();
    }

    transitionEnter() {
        gsap.killTweensOf(this._props, 'all');
        gsap.to(this._props, {
            blur: 0,
            duration: 0.6,
            ease: 'power2.out',
            onUpdate: () => {
                gsap.set(this.content, { '--blur': `${this._props.blur}px` });
            },
        });
        gsap.to(this.nameCardRef, {
            opacity: 1,
            duration: 0.2,
            ease: 'power1.inOut',
        });
        this.textAnim.play();
    }

    transitionLeave() {
        gsap.killTweensOf(this._props, 'all');
        gsap.to(this._props, {
            blur: 8,
            duration: 0.4,
            ease: 'power2.out',
            onUpdate: () => {
                gsap.set(this.content, { '--blur': `${this._props.blur}px` });
            },
        });

        gsap.to(this.nameCardRef, {
            opacity: 0,
            duration: 0.2,
            ease: 'power1.inOut',
        });
    }

    createLink() {
        const _link = document.createElement('a');

        _link.classList.add('model');
        _link.href = `/model/${this.name_en_slug}/`;
        // _link.href = "/detail";

        _link.innerHTML = `
        <span class="model-sticky">
            <span class="model-sticky-info">
                <span class="sticky-hover">
                    <span class="sticky-bg"></span>
                    <span class="name">${this.name_en}</span>
                    <span class="category"></span>
                </span>
            </span>
        </span>
        <img src="${this.thumbnail}" alt="${this.name_en}" />`;

        this.nameText = _link.querySelector('.sticky-hover .category');
        this.textAnim = new TextAnim({ _result: this.individuality_keyword_en, _delay: 0, content: this.nameText });
        this.content = _link;

        this.nameCardRef = this.content.querySelector('.model-sticky');

        if (!this.isMobile) {
            gsap.set(_link, { '--blur': `8px` });
            this.bindTransition();
        }

        this.container.appendChild(_link);
    }

    bindTransition() {
        if (!this.isMobile) {
            this._props = { blur: 8 };
            gsap.set(this.content, { '--blur': `${this._props.blur}px` });

            const transitionIn = this.transitionEnter.bind(this);
            const transitionOut = this.transitionLeave.bind(this);

            // this.content.addEventListener("pointerenter", transitionIn);
            this.content.addEventListener('mouseenter', transitionIn);

            // this.content.addEventListener("pointerdown", transitionIn);

            // this.content.addEventListener("pointerleave", transitionOut);
            this.content.addEventListener('mouseleave', transitionOut);
        }
    }
}

class EmptyGrid {
    constructor(props) {
        this.props = props;
        this.isMobile = document.body.classList.contains('view-mobile');

        this.texts = [];
        this.createLink();
    }

    createLink() {
        const { text, link, bottom } = this.props;

        const content = document.createElement('div');
        content.classList.add('grid-container');

        gsap.set(content, { justifyContent: bottom ? 'flex-end' : 'flex-start' });

        const _arr = text.split('<br />');

        _arr.map((a, i) => {
            const el = document.createElement('div');
            el.classList.add('text-grid');
            content.appendChild(el);
            const textAnim = new TextAnim({ _result: a, _delay: 0, content: el, init: true });
            this.texts[i] = textAnim;
        });

        if (link) {
            const val = { link: link, text: 'CONTACT US', content: content };
            const dd = new ButtonBracket(val);
        }

        const container = document.querySelector('.model-container');

        container.append(content);
        this.content = content;
        this.bindTransition();
    }

    transitionEnter() {
        this.texts.forEach((t) => t.anim());
    }

    bindTransition() {
        if (!this.isMobile) {
            const transitionIn = this.transitionEnter.bind(this);
            this.content.addEventListener('mouseenter', transitionIn);
        }
    }
}
