import axios from "axios";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ButtonBracket from "./ButtonBracket";
import Viewer from "./Viewer";

export default class PG_Theme_detail {
    constructor() {
        const url = new URL(window.location.href);
        const urlParams = url.searchParams;

        const name = urlParams.get("name");
        this.viewer = new Viewer();
        this.loadThemeDetail(name);
        this.isMobile = document.body.classList.contains("view-mobile");

        this.isNext = null;
        // this.loadThemeDetailList()
    }
    async loadThemeDetail(name) {
        try {
            const response = await axios.get(`https://stage-api.blurblur.ai/api/themes/${name}`);

            this.createContent(response.data);

            if (response.data.next) {
                this.isNext = response.data.next;
                this.loadNextElement();
            }
        } catch (error) {

        } finally {
        }
    }

    async nextLoad() {
        const response = await axios.get(this.isNext);


        const { results } = response.data;

        const container = document.querySelector(".theme-thumbnail");
        results.map((r) => {
            container.appendChild(this.createViewer(r));
        });

        if (response.data.next) {
            this.isNext = response.data.next;
        }
    }

    async loadNextElement() {
        ScrollTrigger.create({
            trigger: ".theme-thumbnail",
            start: () => `top-=20% bottom`,
            once: true,
            onEnter: () => {
                this.nextLoad();
            },
        });
    }

    createViewer(props) {
        const { resize_image, id, type } = props;
        const button = document.createElement("button");
        button.classList.add("model-thumb");
        button.innerHTML = `<img data-src=${props.resize_image ?? props.image} />`;
        const image = button.querySelector("img");
        image.src = image.getAttribute("data-src");
        image.onload = () => {
            if (!image.hasAttribute("data-load")) {
                image.setAttribute("data-load", true);
                gsap.to(button, { opacity: 1, duration: 0.4, ease: "power1.inOut" });
            }
        };

        button.addEventListener("click", () => {
            if (this.isMobile) {
                window.location.href = `https://blurblur.ai/view?type=theme&index=${id}`;
            } else {
                this.viewer.loadDATA("theme", id);
            }
            // this.viewer.createLayer({
            //     index: id,
            //     name_en: model_en_name.toLowerCase(),
            //     slug: model_en_name.toLowerCase().split(" ").join("-"),
            //     name: model_en_name.toLowerCase(),
            //     image: image,
            // });
        });

        return button;
    }
    createContent(props) {

        const { results, theme_name, first_image } = props;

        const container = document.querySelector(".theme-container");
        const content = document.createElement("div");
        // const _link = `/theme/detail?name=${name.toLowerCase().split(" ").join("-")}`;

        content.classList.add("theme-content");

        content.innerHTML = `
            <div class="theme-info">
                <div class="title">${theme_name.split("-").join(" ")}</div>
            </div>
            <div class="theme-thumbnail"></div>
        `;

        content.querySelector(".theme-thumbnail").appendChild(this.createViewer(first_image));

        results.map((r) => {
            content.querySelector(".theme-thumbnail").appendChild(this.createViewer(r));
        });
        container.appendChild(content);
    }
}
