import gsap from 'gsap';
import axios from 'axios';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/dist/ScrollSmoother';
import TextAnim from './TextAnim';

import { openAccordion, toggleAccordion, toast, copyClipboard } from './utis';
import Viewer from './Viewer';
import ButtonBracket from './ButtonBracket';

import Detail from './Detail';

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
export default class PG_Model {
    createPortfolioImage(images, transition) {
        let _arr = [];
        for (let i = 0; i < images.length; i++) {
            if (i % 2 == 0) {
                _arr[i] = new Array();
                _arr[i][0] = images[i];
            } else {
                _arr[i - 1][1] = images[i];
            }
        }

        _arr.map((a) => {
            const _el = document.createElement('div');
            _el.classList.add('grid-item');
            _el.classList.add('row');
            a.map((j, i) => {
                if (transition) {
                    _el.innerHTML += `<button class="model-thumb button-viewer" data-type=${j.type} data-index=${j.id}>
                        <img alt=${this.name_en_slug} data-src=${j.resize_image} />
                    </button>`;

                    const _b = _el.querySelectorAll('.button-viewer');
                    gsap.set(_b[_b.length - 1].querySelector('img'), { opacity: 0 });
                } else {
                    _el.innerHTML += `<button class="model-thumb button-viewer" data-type=${j.type} data-index=${j.id}>
                        <img alt=${this.name_en_slug} src=${j.resize_image} />
                    </button>`;
                }

                if (i == a.length - 1) {
                    gsap.utils.toArray('.button-viewer', _el).forEach((button) => {
                        button.addEventListener('click', () => {
                            const _type = button.getAttribute('data-type');
                            const _index = button.getAttribute('data-index');
                            if (this.isMobile) {
                                let _type1 = _type == 'portfolio' ? 'port' : _type;
                                window.location.href = `https://blurblur.ai/view?type=${_type1}&index=${_index}`;
                            } else {
                                this.viewer.loadDATA(_type, _index);
                            }
                        });
                    });

                    if (transition) {
                        this.repositionPortfolioImage(true);
                        let _dt = 0;
                        gsap.utils.toArray('.button-viewer', _el).forEach((button) => {
                            button.querySelector('img').src = button.querySelector('img').getAttribute('data-src');

                            if (!this.loadMore) {
                                if (document.querySelector('.view-more-content')) {
                                    gsap.to(document.querySelector('.view-more-content'), {
                                        opacity: 0,
                                        duration: 0.2,
                                        display: 'none',
                                        ease: 'power1.out',
                                        onComplete: () => {
                                            if (document.querySelector('.view-more-content')) {
                                                document.querySelector('.view-more-content').remove();
                                            }
                                        },
                                    });
                                }
                            }
                            button.querySelector('img').onload = () => {
                                _dt += 0.2;
                                gsap.to(button.querySelector('img'), { opacity: 1, duration: 0.3, ease: 'power1.inOut', delay: _dt });
                            };
                        });
                    } else {
                        this.repositionPortfolioImage();
                    }
                }
            });
            if (document.querySelector('.interview-container')){
                document.querySelector('.interview-container').appendChild(_el);
            }
        });

        if (document.querySelector('.section-interview') && document.querySelectorAll('.section-interview .model-thumb').length < 6) {
            document.querySelector('.section-interview').classList.add('empty');
        }
    }
    async loadMoreImage(url) {
        try {
            const response = await axios.get(`https://stage-api.blurblur.ai${url}`);

            if (response && response.status === 200) {
                const { next, prev, results } = response.data;
                if (results) {
                    if (next == null) {
                        this.loadMore = false;
                    }

                    this.createPortfolioImage(results, true);
                }
            }
        } catch (error) {
        } finally {
        }
    }

    async loadDATA() {
        try {
            const url = new URL(window.location.href);
            const urlParams = url.searchParams;
            const type = urlParams.get('model');

            const response = await axios.get(`https://stage-api.blurblur.ai/api/ai-model/${url.pathname.split('/')[2]}`);

            if (!this.toload) {
                this.toload = true;
                if (!document.querySelector('.page .page-container')) {
                    document.querySelector('.page').appendChild(new Detail(response.data));
                    gsap.utils.toArray('.contact-sticky .button-icon').forEach((button, i) => {
                        if (i == 0) {
                            button.setAttribute('data-value', `https://blurblur.ai/model/${response.data.name_en_slug}/`);
                        }
                        if (i == 1) {
                            button.href = `/contact?model=${response.data.id}`;
                        }
                    });
                }

                this.name_en_slug = response.data.name_en_slug;
                gsap.delayedCall(0.1, () => {
                    this.createPortfolioImage(response.data.portfolio_and_theme_image);

                    if (response.data.more_image_url) {
                        this.loadMore = true;
                        const _container = document.querySelector('.section-interview');

                        const buttonContainer = document.createElement('div');
                        buttonContainer.classList.add('view-more-content');
                        buttonContainer.innerHTML = `
                        <button class="button-bracket">
                        <span class="icon-bracket"></span><span class="rect"></span>
                        <span class="row"><span class="text">VIEW MORE</span><span class="rect"></span><span class="icon-plus"></span></span>
                        <span class="rect"></span><span class="icon-bracket reverse"></span>
                        </button>
                        `;

                        _container.appendChild(buttonContainer);

                        new ButtonBracket({ button: buttonContainer.querySelector('.button-bracket') });

                        const _button = buttonContainer.querySelector('.button-bracket');
                        _button.setAttribute('data-next-url', response.data.more_image_url);
                        _button.addEventListener('click', () => {
                            if (_button.hasAttribute('data-next-url')) {
                                this.loadMoreImage(_button.getAttribute('data-next-url'));
                            }
                        });
                    }

                    this._initEvent();
                });
            }
        } catch (error) {
        } finally {
        }
    }

    _initEvent() {
        this.slug = document.querySelector('.model-name-en').innerHTML.split(' ').join('-').toLowerCase();
        this.name_en = document.querySelector('.model-name-en').innerHTML;
        this.init();
        this.viewer = new Viewer();
        this.name_locale = document.querySelector('.share-value').innerHTML;
        this.txtAnim = false;
        new ButtonBracket({ button: document.querySelector('.footer .button-bracket') });
        if (this.hasInterview) {
            window.onresize = () => {
                this.interviewLayout();
            };
        }
        ScrollTrigger.getAll().forEach((st) => st.refresh());
        this.initViewer();
    }

    constructor() {
        this.loadMore = false;
        this.hasInterview = true;
        this.loadDATA();
    }

    initViewer() {
        const buttonShare = document.querySelector('.button-share');

        buttonShare.addEventListener('click', () => {
            copyClipboard(buttonShare);
        });
    }

    interviewLayout() {
        if (window.innerWidth > 768) {
            const container = document.querySelector('.interview-container');
            if (!container) {
                this.hasInterview = false;
                return;
            }
            const text = document.querySelector('.interview-column');

            let grids = gsap.utils.toArray('.grid-item');

            let _total = 0;
            const _left = window.innerWidth > 768 ? text.clientWidth + 6 : 0;
            const firstEl = grids[0];

            grids = grids.filter((_, i) => i !== 0);

            const _yv = window.innerWidth < 768 ? text.clientHeight + 68 : 0;

            gsap.set(firstEl, { top: 12 + _yv, left: _left, width: container.clientWidth - text.clientWidth - 6 });

            _total += firstEl.clientHeight + 12 + _yv;

            let _ct = 0;
            let _prev;

            grids.forEach((g, i) => {
                if (_total > text.clientHeight) {
                    if (_ct == 1) {
                        gsap.set(g, { top: _prev, left: _left, width: text.clientWidth - 6 });
                        if (g.querySelectorAll('.model-thumb').length == 1) {
                            gsap.set(grids[i - 1], { x: `calc(50% + 6px)` });
                            grids[i - 1].style.transform = 'translateX(calc(50% + 6px)';
                            grids[i - 1].style.zIndex = '3';
                        } else {
                            if (grids[i - 1]) {
                                gsap.set(grids[i - 1], { clearProps: 'transform, zIndex' });
                            }
                        }
                        _ct = 0;
                    } else {
                        if (grids[i - 1]) {
                            gsap.set(grids[i - 1], { clearProps: 'transform, zIndex' });
                        }
                        if (i == grids.length - 1) {
                            gsap.set(g, { top: 12 + _total, left: _left, x: 0, width: text.clientWidth - 6 });
                        } else {
                            gsap.set(g, { top: 12 + _total, left: 0, x: 0, width: text.clientWidth - 6 });
                        }

                        _prev = _total + 12;
                        _total += g.clientHeight + 12;
                        _ct++;
                    }
                } else {
                    gsap.set(g, { top: 12 + _total, left: _left, x: 'calc(100% + 6px)', width: text.clientWidth - 6 });
                    _total += g.clientHeight + 12;
                }

                gsap.set(container, { minHeight: _total });
            });
        }
    }

    init() {
        this.isMobile = document.body.classList.contains('view-mobile');

        this.toload = false;
        this.content = document.querySelector('.page-detail');

        this.bv = {
            blur: 0,
        };
        const mainImage = this.content.querySelector('.model-profile .model-thumb img');
        mainImage.src = '';

        mainImage.src = this.isMobile ? mainImage.getAttribute('data-src-mo') : mainImage.getAttribute('data-src');
        mainImage.onload = () => {
            if (!mainImage.hasAttribute('data-load')) {
                mainImage.setAttribute('data-load', true);
                this.intro();
            }
        };

        this.initTextAnim();

        this.repositionPortfolioImage();
        // this.initEvent();

        // new ButtonBracket({ button: document.querySelector(".contact-sticky .button-bracket") });
    }

    repositionPortfolioImage(nodelay) {
        gsap.delayedCall(nodelay ? 0 : 0.8, () => {
            if (!this.isMobile) {
                this.interviewLayout();
                if (this.hasInterview) {
                    if (document.querySelector('.interview-container')) {
                        if (!document.querySelector('.interview-container').hasAttribute('data-load')) {
                            document.querySelector('.interview-container').setAttribute('data-load', true);
                            gsap.to('.interview-container', { opacity: 1, duration: 0.4, ease: 'power1.inOut' });
                        }
                    }
                }
            }
            gsap.delayedCall(0.4, () => {
                if (ScrollTrigger.getById('sticky')) {
                    ScrollTrigger.getById('sticky').refresh();
                }
            });
        });
    }

    intro() {
        if (!document.body.classList.contains('load')) {
            document.body.classList.add('load');
        }
        const self = document.querySelector('.page-detail');
        const visualRef = self.querySelector('.model-thumb span');
        const featureRef = document.querySelector('.detail');
        const featureStickyRef = document.querySelector('.feature-accordion-container');

        const nameRef = document.querySelector('.title-name');
        const stickyInnerRef = document.querySelector('.sticky-container');
        const main = document.querySelector('.page-container');

        const footerRef = document.querySelector('.section-related-model');
        gsap.delayedCall(0, () => {
            const tl = gsap.timeline({ delay: 0 });

            // main animation
            tl.addLabel('st');
            if (visualRef) {
                tl.to(
                    nameRef,
                    {
                        opacity: 1,
                        duration: 1.1,
                        ease: 'power1.inOut',
                        onComplete: () => {},
                    },
                    'st'
                );
                tl.to(
                    self.querySelector('.feature-container'),
                    {
                        opacity: 1,
                        duration: 1.1,
                        ease: 'power1.inOut',
                        onComplete: () => {},
                    },
                    'st'
                );
                tl.to(
                    visualRef,
                    {
                        opacity: 1,
                        duration: 1.1,
                        ease: 'power1.inOut',
                        onComplete: () => {
                            // const pinImages = document.querySelectorAll(".pin-images img");
                            // gsap.to(pinImages, {
                            //     opacity: 1,
                            //     duration: 0.8,
                            //     ease: "power1.out",
                            // });
                        },
                    },
                    'st'
                );
                tl.to(
                    visualRef,
                    {
                        scale: 1,
                        duration: 1.1,
                        ease: 'power3.out',
                        onComplete: () => {},
                    },
                    'st'
                );
            }

            gsap.delayedCall(0.3, () => {
                gsap.to(self.querySelectorAll('.main-thumb'), {
                    opacity: 1,
                    duration: 1.3,
                    ease: 'power1.inOut',
                });
                gsap.to(self.querySelectorAll('.main-thumb'), {
                    scale: 1,
                    duration: 1.55,
                    ease: 'power1.out',
                    onComplete: () => {},
                });
                gsap.to(self.querySelectorAll('.el-text'), {
                    opacity: 1,
                    duration: 0.8,
                    delay: 1,
                    ease: 'power1.inOut',
                });
            });

            this.trigger(true);
        });

        ScrollTrigger.refresh();
    }

    trigger() {
        const featureRef = document.querySelector('.detail');
        const featureStickyRef = document.querySelector('.feature-accordion-container');

        const nameRef = document.querySelector('.title-name');
        const stickyRef = document.querySelector('.contact-sticky');
        const stickyInnerRef = document.querySelector('.sticky-container');
        const main = document.querySelector('.page-container');

        const footerRef = document.querySelector('.section-related-model');
        let mm = gsap.matchMedia();

        mm.add('(max-width: 768px)', () => {
            ScrollTrigger.create({
                trigger: document.querySelector('.feature-container'),
                start: () => `bottom bottom-=60px`,
                // once: true,

                onEnter: () => {
                    if (!this.txtAnim) {
                        this.textAnims.forEach((t) => {
                            t.delay = 0;
                            t.play();
                        });
                        this.txtAnim = true;
                    }
                },
            });
            ScrollTrigger.create({
                trigger: nameRef,
                start: () => 'bottom-=28px top',
                endTrigger: document.querySelector('.section-use'),
                end: () => `top-=60px bottom`,
                refreshPriority: 0,
                id: 'sticky',
                onToggle: (self) => {
                    if (self.isActive) {
                        gsap.to(stickyInnerRef, { y: 0, duration: 0.6, ease: 'power2.out' });
                    } else {
                        gsap.to(stickyInnerRef, { y: '100%', duration: 0.6, ease: 'power2.out' });
                    }
                },
                // onToggle: (self) => {
                //     if (self.isActive) {
                //         gsap.set(stickyInnerRef, { opacity: 1 });
                //     }
                // },
            });

            const self = document.querySelector('.page-detail');
            const images = self.querySelectorAll('.el-image');

            images.forEach((image, i) => {
                if (image.classList.contains('center')) {
                    gsap.to(image, {
                        scale: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: image,
                            start: 'top bottom-=60px',
                            end: () => `150% 80%`,
                            scrub: true,
                            id: i,
                        },
                    });
                } else {
                    gsap.to(image, {
                        scale: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: image,
                            start: 'top bottom-=60px',
                            end: () => `200% 80%`,

                            scrub: true,
                            id: i,
                        },
                    });
                }
            });

            const contents = self.querySelectorAll('.el-content');

            contents.forEach((content, index) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => 'top-=10% bottom-=100px',
                    end: () => 'top-=10% 20%',
                    onEnter: () => {
                        if (content.classList.contains('no-y')) {
                            gsap.to(content, {
                                opacity: 1,
                                duration: 1,
                                ease: 'power1.inOut',
                            });
                        } else {
                            gsap.to(content, {
                                y: 0,
                                duration: 0.65,
                                ease: 'power2.out',
                            });
                            gsap.to(content, {
                                opacity: 1,
                                duration: 0.9,
                                ease: 'power1.out',
                                onComplete: () => {
                                    content.classList.add('load');
                                },
                            });
                        }
                    },
                });
            });

            const contentscustom = self.querySelectorAll('.el-contnet-custom');

            contentscustom.forEach((content, index) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => `top-=10% bottom-=60px`,

                    // id: "----text",
                    end: () => 'top-=10% 20%',
                    onEnter: () => {
                        gsap.to(content, {
                            y: 0,
                            duration: 0.8,
                            ease: 'power2.out',
                        });
                        gsap.to(content, {
                            opacity: 1,
                            duration: 0.8,
                            ease: 'power2.inOut',
                        });
                        gsap.to('.section-use .line', {
                            scaleX: 1,
                            duration: 0.9,
                            ease: 'power3.out',
                            onComplete: () => {
                                content.classList.add('load');
                            },
                        });
                    },
                });
            });

            const pinContents = document.querySelectorAll('.feature-accordion-container .accordion-content');

            gsap.set(pinContents, { opacity: 0, y: 0 });
            pinContents.forEach((content, i) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => 'top+=40px bottom-=66px',
                    end: () => 'top-=10% 20%',

                    id: 'content',
                    once: true,
                    onEnter: () => {
                        // gsap.to(content, {
                        //     y: 0,
                        //     duration: 0.5,
                        //     ease: "power2.out",
                        // });
                        gsap.to(content, {
                            opacity: 1,
                            duration: 0.7,
                            ease: 'power1.inOut',
                            onComplete: () => {
                                content.classList.add('load');
                            },
                        });
                    },
                });
            });

            const accordionContents = document.querySelectorAll('.section-about .accordion-content');
            // ac
            gsap.set(accordionContents, { opacity: 0, y: 50 });
            accordionContents.forEach((content, i) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => 'top-=30px bottom-=66px',
                    end: () => 'top-=10% 20%',

                    id: 'content',
                    once: true,
                    onEnter: () => {
                        gsap.to(content, {
                            y: 0,
                            duration: 0.6,
                            ease: 'power2.out',
                        });
                        gsap.to(content, {
                            opacity: 1,
                            duration: 1.1,
                            ease: 'power1.out',
                            onComplete: () => {
                                content.classList.add('load');
                            },
                        });
                    },
                });
            });
        });

        const self = document.querySelector('.page-detail');

        // const contents2 = gsap.utils.toArray(self.querySelectorAll(".section-interview .container .el-content"));
        // contents2.push(self.querySelector(".interview-info.el-content.no-y"));
        // contents2.forEach((content, index) => {
        //     ScrollTrigger.create({
        //         trigger: content,
        //         start: () => "top-=10% bottom-=100px",
        //         end: () => "top-=10% 20%",
        //         onEnter: () => {
        //             if (content.classList.contains("no-y")) {
        //                 gsap.to(content, {
        //                     opacity: 1,
        //                     duration: 0.8,
        //                     ease: "power1.inOut",
        //                 });
        //             } else {
        //                 gsap.to(content, {
        //                     y: 0,
        //                     duration: 0.65,
        //                     ease: "power2.out",
        //                 });
        //                 gsap.to(content, {
        //                     opacity: 1,
        //                     duration: 0.9,
        //                     ease: "power1.out",
        //                 });
        //             }
        //         },
        //     });
        // });

        mm.add('(min-width: 769px)', () => {
            if (window.innerWidth > 768) {
                if (!this.txtAnim) {
                    this.txtAnim = true;
                    this.textAnims.forEach((t) => t.play());
                }
            }
            ScrollTrigger.create({
                trigger: featureRef,
                pin: featureStickyRef,
                pinType: 'transform',
                // anticipatePin: 1,
                pinSpacing: false,
                id: 'pins1',
                start: () => `top top+=64px`,
                end: () => `bottom bottom`,
                refreshPriority: 0,
                onEnter: () => {},
            });
            ScrollTrigger.create({
                trigger: featureRef,
                once: true,
                start: () => `top-=64px top`,
                onEnter: () => {
                    // openAccordion(document.querySelector(".accordion-container"));
                },
            });
            ScrollTrigger.create({
                trigger: nameRef,
                start: () => 'bottom-=28px top',
                endTrigger: document.querySelector('.section-use'),
                end: () => `top-=60px bottom`,
                refreshPriority: 0,
                id: 'sticky',
                onToggle: (self) => {
                    if (self.isActive) {
                        gsap.to(stickyInnerRef, { y: 0, duration: 0.6, ease: 'power2.out' });
                    } else {
                        gsap.to(stickyInnerRef, { y: '100%', duration: 0.6, ease: 'power2.out' });
                    }
                },
                // onToggle: (self) => {
                //     if (self.isActive) {
                //         gsap.set(stickyInnerRef, { opacity: 1 });
                //     }
                // },
            });
            ScrollTrigger.create({
                trigger: main,
                start: () => 'top top',
                endTrigger: main,
                end: () => `bottom+=400px bottom`,
                pin: stickyRef,
                pinSpacing: false,
                pinType: 'fixed',
                anticipatePin: 1,
                id: 'pins2',
            });

            const pinContents = document.querySelectorAll('.feature-accordion-container .accordion-content');

            gsap.set(pinContents, { opacity: 0, y: 0 });
            pinContents.forEach((content, i) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => 'top+=40px bottom-=66px',
                    end: () => 'top-=10% 20%',

                    id: 'content',
                    once: true,
                    onEnter: () => {
                        // gsap.to(content, {
                        //     y: 0,
                        //     duration: 0.5,
                        //     ease: "power2.out",
                        // });
                        gsap.to(content, {
                            opacity: 1,
                            duration: 0.7,
                            ease: 'power1.inOut',
                        });
                    },
                });
            });

            const accordionContents = document.querySelectorAll('.section-about .accordion-content');

            gsap.set(accordionContents, { opacity: 0, y: 70 });
            accordionContents.forEach((content, i) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => 'top+=10px bottom-=66px',
                    end: () => 'top-=10% 20%',

                    id: 'content',
                    once: true,
                    onEnter: () => {
                        gsap.to(content, {
                            y: 0,
                            duration: 0.6,
                            ease: 'power2.out',
                        });
                        gsap.to(content, {
                            opacity: 1,
                            duration: 0.9,
                            ease: 'power1.out',
                        });
                    },
                });
            });

            const self = document.querySelector('.page-detail');

            const contents = self.querySelectorAll('.el-content');

            contents.forEach((content, index) => {
                if (!content.classList.contains('no-y')) {
                    ScrollTrigger.create({
                        trigger: content,
                        start: () => 'top+=10px bottom-=66px',
                        end: () => 'top-=10% 20%',

                        id: 'content',
                        once: true,
                        onEnter: () => {
                            gsap.to(content, {
                                y: 0,
                                duration: 0.6,
                                ease: 'power2.out',
                            });
                            gsap.to(content, {
                                opacity: 1,
                                duration: 0.9,
                                ease: 'power1.out',
                            });
                        },
                    });
                }
            });

            contents.forEach((content, index) => {
                if (content.classList.contains('no-y')) {
                    ScrollTrigger.create({
                        trigger: content,
                        start: () => 'top+=15px bottom-=66px',
                        end: () => 'top-=10% 20%',

                        id: 'no-y',
                        once: true,
                        onEnter: () => {
                            gsap.to(content, {
                                opacity: 1,
                                duration: 1,
                                ease: 'power1.inOut',
                            });
                        },
                    });
                }
            });

            const contentscustom = self.querySelectorAll('.el-contnet-custom');

            contentscustom.forEach((content, index) => {
                ScrollTrigger.create({
                    trigger: content,
                    start: () => 'top+=6% bottom-=100px',
                    end: () => 'top-=10% 20%',

                    id: 'text',
                    onEnter: () => {
                        gsap.to(content, {
                            y: 0,
                            duration: 0.8,
                            ease: 'power1.out',
                        });
                        gsap.to(content, {
                            opacity: 1,
                            duration: 1.0,
                            ease: 'power1.inOut',
                        });

                        gsap.to('.section-use .line', {
                            scaleX: 1,
                            duration: 1.5,
                            ease: 'power3.out',
                        });
                    },
                });
            });

            const images = self.querySelectorAll('.section-interview .el-image');

            images.forEach((image, i) => {
                if (image.classList.contains('center')) {
                    // gsap.set(image, { bordeR: "3px solid blue" });
                    gsap.to(image, {
                        scale: 1,
                        y: 0,
                        scrollTrigger: {
                            refreshPriority: 0,
                            trigger: '.interview-column',
                            start: () => `top+=${image.clientHeight * 0.04}px bottom-=60px`,
                            end: () => `+${image.clientHeight * 0.86}px`,

                            scrub: true,
                            id: i,
                        },
                    });
                } else {
                    // gsap.set(image, { border: "3px solid red" });
                    gsap.to(image, {
                        scale: 1,
                        y: 0,
                        scrollTrigger: {
                            trigger: image,
                            start: () => '20% bottom-=60px',
                            end: () => `+=${image.clientHeight * 2}px 80%`,
                            scrub: true,
                            id: i,
                        },
                    });
                }
            });
        });

        ScrollTrigger.refresh();
    }

    initEvent() {
        gsap.utils.toArray('.accordion-container').forEach((accrordion, index) => {
            accrordion.querySelector('.accordion-button').addEventListener('click', () => {
                toggleAccordion(accrordion);
            });
        });
    }

    initTextAnim() {
        this.textAnims = [];
        this.texts = gsap.utils.toArray('.feature-container .info-feature', this.content);

        // to data valiue

        const values = ['Mar 5, 2023', 'Cosemetic Campaign', 'Delicate, Professional, Trustworthy'];

        this.texts.forEach((text, i) => {
            const t = text.querySelectorAll('.feature-value');

            const anim = new TextAnim({ _result: t[0].innerHTML, _delay: 0.5, content: t[t.length - 1] });
            this.textAnims[i] = anim;
        });
    }
}
